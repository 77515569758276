import apiService from "./apiService";


export function getSessionsAPI(module_id) {
    return apiService({
        url: `/session`,
        method: 'GET',
        module_id
    })
}
export function getSessionByIdAPI(id, module_id) {
    return apiService({
        url: `/session/${id}`,
        method: 'GET',
        module_id
    })
}

export function makeCurrentSessoinAPI(id, module_id) {
    return apiService({
        url: `/session/set-current-session/${id}`,
        method: 'GET',
        module_id
    })
}

export function addSessionsAPI(payload, module_id) {
    return apiService({
        url: `/session`,
        data : payload,
        method: 'POST',
        module_id
    })
}
export function deleteSessionsAPI(id,  module_id) {
    return apiService({
        url: `/session/${id}`,
        method: 'DELETE',
        module_id
    })
}
export function updateSessionsAPI(id, payload, module_id) {
    return apiService({
        url: `/session/${id}`,
        data : payload,
        method: 'PATCH',
        module_id
    })
}