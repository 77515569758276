import CommonUtil from "src/shared/CommonUtil";
import apiService from "./apiService";
const schoolId = CommonUtil.getSelectedSchool()


export function postSubApi(payload,
    module_id) {
    return apiService({
        url: "/subject",
        data: payload,
        method: 'POST',
        module_id
    });
}

export function postCombinedApi(payload,
    module_id) {
    return apiService({
        url: "/subject/combine",
        data: payload,
        method: 'POST',
        module_id
    });
}

export function getSubApi(schid, params, module_id) {
    const schoolId = CommonUtil.getSelectedSchool()
    return apiService({
        url: `/subject/school/${schoolId}`,
        params: params,
        method: 'GET',
        module_id
    });
}
export function getSubClassWiseAPI(module_id) {
    const schoolId = CommonUtil.getSelectedSchool()
    return apiService({
        url: `/subject/schools/${schoolId}`,
        method: 'GET',
        module_id
    });
}

export function editSubApi(editId, payload,
    module_id) {
    return apiService({
        url: `/subject/${editId}`,
        data: payload,
        method: 'PATCH',
        module_id
    });
}

export function deleteSubApi(subId,
    module_id) {
    return apiService({
        url: `/subject/${subId}`,
        data: {},
        method: 'DELETE',
        module_id
    });
}
export function getSpecializationsAPI(params, module_id) {
    return apiService({
        url: `/specialization/schoolId/${schoolId}`,
        params: params,
        method: 'GET',
        module_id
    });
}

export function postSplAPI(name, classId, module_id) {
    return apiService({
        url: "/specialization",
        data: {
            "schoolId": schoolId,
            "classId": classId,
            "name": name
        },
        method: 'POST',
        module_id
    });
}

export function updateSplAPI(id, name, module_id) {
    return apiService({
        url: `/specialization/${id}`,
        data: {
            "name": name
        },
        method: 'PATCH',
        module_id
    });
}
export function deleteSplAPI(id, module_id) {
    return apiService({
        url: `/specialization/${id}`,
        method: 'DELETE',
        module_id
    });
}

export const assignSpecializationAPI = (payload, module_id) => {
    return apiService({
        url: `/assing-specialization`,
        data: payload,
        method: 'POST',
        module_id
    });
}

export const updateAssignedSpecializationAPI = (id, payload, module_id) => {
    return apiService({
        url: `/assing-specialization/${id}`,
        data: payload,
        method: 'PATCH',
        module_id
    });
}

export const getSubjectByStudent = (id) => {
    return apiService({
        url: `/subject/student?studentId=${id}`,
        method: 'GET',
    });
}