import { RiLockPasswordFill } from "react-icons/ri";
import React, { useContext, useState } from 'react'
import {
  CLoadingButton,
} from '@coreui/react-pro';
import "./customLogin.scss"
import { login } from 'src/api/loginSignUpService';
import CommonUtil from 'src/shared/CommonUtil';
import moment from "moment";
import { Link, useNavigate } from 'react-router-dom';
import { getRegisteredSchool } from 'src/api/schoolRegisterService';
import { getRoleAccessApi } from 'src/api/accessRoleService';
import { getModulesApi } from 'src/api/moduleServices';
import data from 'src/data.json';
import { FaUserAlt } from 'react-icons/fa';
import { BiLogIn } from 'react-icons/bi';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import toast from "react-hot-toast";
import { appContext } from "src/App";
import mlcLogo from "src/assets/logos/mlc.png"
import { getEmployeeByIdAPI } from "src/api/employeeService";



const CustomLogin = () => {

  const navigate = useNavigate();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { fetchTags, getDepartments, getDesignations, getEmployeeTypes, getEmployees, getDays, getSessions, getClassList, runHolidayCron } = useContext(appContext)
  const [subdomain, setSubdomain] = useState('');

  const extractSubdomain = () => {
    const hostname = window.location.hostname;
    const hostnameParts = hostname.split('.');
    if (hostnameParts.length > 3) {
      setSubdomain(hostnameParts[1]);
    } else {
      setSubdomain(hostnameParts[0]);
    }
  };



  React.useEffect(() => {
    extractSubdomain()
    if (CommonUtil.getLocalStorage('refresh_token_exp_time') &&
      moment(CommonUtil.getLocalStorage('refresh_token_exp_time')).diff(moment(), "seconds") > 0) {
      navigate('/dashboard');
    } else {
      CommonUtil.setLocalStorage('refresh_token_exp_time', null)
    };
  }, []);

  const getRoleAccess = async (params) => {
    try {
      let resp = await getRoleAccessApi(params, "", true)
      if (resp?.code === 200) {
        console.log(resp?.data, "roleAccess")
        CommonUtil.setJsonLocalStorage('roleAccess', resp?.data)
      } else {
        throw new Error(resp?.data?.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getModules = async () => {
    try {
      let resp = await getModulesApi(true)
      if (resp?.code === 200) {
        CommonUtil.setJsonLocalStorage('moduleData', resp?.data)
      } else {
        throw new Error(resp?.data?.message)
      }
    } catch (error) {
      console.log(error)
    }
  }


  const getschoolData = async (id) => {
    try {
      let resp = await getRegisteredSchool({ id });
      if (resp && resp.code === 200) {
        let schoolData = resp?.data?.rows[0];
        CommonUtil.setLocalStorage("schoolCode", schoolData?.code)
        CommonUtil.setLocalStorage("selectedSchoolLogo", schoolData?.logo);
        CommonUtil.setLocalStorage("selectedSchoolAddress", schoolData?.address);
        CommonUtil.setLocalStorage("isSchool", schoolData?.isSchool);
        CommonUtil.setLocalStorage("allowInstallment", schoolData?.allowInstallment);
        CommonUtil.setLocalStorage("isPremium", schoolData?.isPremium);
        CommonUtil.setLocalStorage("school_id", schoolData?.schoolId);
        const permittedModules = schoolData?.permittedModules;
        CommonUtil.setJsonLocalStorage("permittedModules", permittedModules);
        CommonUtil.setLocalStorage("schoolAffiliation", schoolData?.affiliation);
        CommonUtil.setJsonLocalStorage("schoolContact", {
          phone: schoolData?.phone,
          email: schoolData?.email,
        });
        CommonUtil.getFile(schoolData?.principalSign, false, "principalSign", false)
        CommonUtil.getFile(schoolData?.schoolSeal, false, "selectedSchoolSeal", false)
      } else {
        console.log(resp)
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getEmployeeById = async (id) => {
    try {
      let resp = await getEmployeeByIdAPI(id)
      if (resp?.code === 200) {
        CommonUtil.setJsonLocalStorage('employeeData', resp?.data)
      } else {
        throw new Error(resp?.data?.message)
      }
    } catch (error) {
      console.log(error)
    }
  }




  const handleLogin = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true)
    try {
      if(!username || !password){
        toast.error("Username and Password are required")
        return
      }
      let resp = await login({
        "username": username,
        "password": password
      });
      if (resp && resp.code === 200) {
        console.log(resp)
        toast.success('Login Successful')
        let tokenData = CommonUtil.jwtDecode(resp?.data?.access.token)
        CommonUtil.setLocalStorage("isAdmin", resp?.data?.is_admin);
        CommonUtil.setJsonLocalStorage('tokenData', tokenData);
        CommonUtil.setLocalStorage('access_role', Number(tokenData.role));
        CommonUtil.setLocalStorage('access_role_id', resp?.data?.role);
        CommonUtil.setLocalStorage('isAdmin', resp?.data?.is_admin);
        CommonUtil.setLocalStorage('isSuperAdmin', resp?.data?.is_super_admin);
        CommonUtil.setJsonLocalStorage("selectedSchool", { "id": tokenData['schoolId'], "name": tokenData['schoolName'], "schoolName": tokenData['schoolName'] })
        CommonUtil.setLocalStorage('access_token', resp?.data?.access.token);
        CommonUtil.setLocalStorage('access_token_exp_time', resp?.data?.access.expires);
        CommonUtil.setLocalStorage('refresh_token', resp?.data?.refresh.token);
        CommonUtil.setLocalStorage('refresh_token_exp_time', resp?.data?.refresh.expires);
        getschoolData(tokenData['schoolId'])
        CommonUtil.setLocalStorage("userId", resp?.data?.user_id);
        CommonUtil.setLocalStorage("employeeId", resp?.data?.employee_id);
        CommonUtil.setLocalStorage("user_name", resp?.data?.user_name || "-");
        if(resp?.data?.employee_id){
          getEmployeeById(resp?.data?.employee_id)
        }
        getModules()
        if (resp?.data?.user_id) {
          await getRoleAccess({
            "accessBy": "user",
            "userId": resp?.data?.user_id
          })
        }
        CommonUtil.setJsonLocalStorage("admissionQueries", data.admissionQueries);
        CommonUtil.setJsonLocalStorage("appointments", data.appointments);
        CommonUtil.setJsonLocalStorage("earningsData", data?.earningsData);
        CommonUtil.setJsonLocalStorage("deductionsData", data?.deductionsData);
        CommonUtil.setJsonLocalStorage("employersContributionsData", data?.employersContributionsData);
        CommonUtil.setJsonLocalStorage("allotedClasses", resp?.data?.allocation);
        CommonUtil.setLocalStorage("isEmployee", resp?.data?.user_name)
        CommonUtil.setLocalStorage("isHeadBranch", resp?.data?.is_head_branch)
        CommonUtil.setLocalStorage("headBranchId", resp?.data?.head_branch_id)
        setTimeout(() => {
          navigate('/dashboard')
          getDepartments()
          getDesignations()
          getEmployeeTypes()
          getEmployees()
          fetchTags(tokenData['schoolId'])
          getDays()
          getClassList()
          getSessions()
          runHolidayCron()
        }, 1000);
      } else {
        toast.error(resp?.data?.message)
        throw new Error(resp?.data?.message)
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false)
    }
  }



  return (
    <div className="custom_login_page_container">
      <div className="login_img_container">
        <div className="login_img" data-aos="fade-right"></div>
      </div>
      <div className="login_form_container">
        <div className="login_form" data-aos="fade-left">
          <form className="login_signup_form" onSubmit={handleLogin}>
            <div className="login_signup_form_header">
              <img src={data.appAssets.find(asset => asset.subdomain === subdomain)?.schoolLogo || data.appAssets[0]?.schoolLogo} width="70px" alt="logo" />
              <h2>Login</h2>
              <small className="mt-2 text-info">
                Welcome to {data.appAssets.find(asset => asset.subdomain === subdomain)?.welcomeTagline || data.appAssets[0]?.welcomeTagline}
              </small>
            </div>
            <div className="login_signup_form_body">
              <div className="username_grp inp_grp">
                <div className="icon">
                  <FaUserAlt />
                </div>
                <input className="input" type="text" placeholder="Username" id="username" value={username} onChange={e => setUsername(e.target.value)} />
              </div>
              <div className="password_grp inp_grp">
                <div className="icon">
                  <RiLockPasswordFill />
                </div>
                <div className="pw_grp input">
                  <input className="" type={showPassword ? "text" : "password"} placeholder="Password" id="password" value={password} onChange={e => setPassword(e.target.value)} />
                  <div className="show_pw_icon" onClick={e => setShowPassword(prev => !prev)} >
                    {
                      showPassword ? <AiFillEye className="text-black" /> : <AiFillEyeInvisible className="text-black" />
                    }
                  </div>
                </div>
              </div>
              <div className="btn_grp">
                <CLoadingButton loading={loading} className="login_signup_btn text-white" color="info" type="submit">
                  <BiLogIn className="fs-4 me-2" /> Login
                </CLoadingButton>
                <div className="logo_container mt-3">
                  <img data-aos="zoom-in" src={mlcLogo} alt="logo" className="logo" />
                  <div className="desc">
                    <div className="heading" data-aos="fade-right">
                      powered by <a href="https://myleadingcampus.com/" target="_blank" rel="noreferrer" > MyLeading Campus<sup>
                        ®
                      </sup> </a>
                    </div>
                  </div>
                </div>
                <div className="heading" data-aos="fade-right">
                  <Link to='/forgot-password' >
                    Forgot Password
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default CustomLogin
