import React from 'react'
import {
    CButton,
    CModal,
    CModalBody,
    CRow,
    CCol,
} from '@coreui/react-pro'

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (<CModal alignment="center" visible={true}>
                <CModalBody>
                    <CRow >
                        <CCol lg={11}>
                            <h5 className='mb-4'>Oops!</h5>
                        </CCol>
                    </CRow>
                    <CRow >
                        <CCol lg={12} className="mb-3">
                           Something went wrong. Please try again little later.
                        </CCol>
                        <CCol lg={12}>
                            <CButton style={{ background: '#3366FF' }}
                                onClick={() => {
                                    localStorage.clear();
                                    window.location.reload();
                                }}
                                className='w-100'>Refresh Page</CButton>
                        </CCol>
                    </CRow>
                </CModalBody>
            </CModal>)
        }

        return this.props.children;
    }
}